import __request__ from 'routing/request';
interface PortInfo {
    readonly protocol: string;
    readonly port: string;
}
interface PaginatedResponse_AssetResponse {
    readonly [_key: string]: Object;
}
interface AssetVulnerabilities {
    readonly scanner_id: string;
    readonly scanner_type: string;
    readonly hostname: string;
    readonly os: string;
    readonly ip: string;
    readonly vulnerabilities: Vulnerability[];
    readonly scan_id: string;
    readonly ports: PortInfo[];
    readonly scan_name: string;
    readonly mac: string;
}
interface Vulnerability {
    readonly severity: 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW' | 'INFO' | 'NONE';
    readonly cve: string;
    readonly vulnerability_id: string;
    readonly cvss: number;
}
interface AssetResponse {
    readonly risk_score_normalized: number;
    readonly rev: number;
    readonly risk_score: number;
    readonly imported_from: string;
    readonly created_at: string;
    readonly dn: string;
    readonly rev_date: string;
    readonly priority: number;
    readonly name: string;
    readonly vulnerabilities: AssetVulnerabilities[];
    readonly details: AssetDetails;
    readonly id: string;
    readonly category: string[];
}
interface AssetDetails {
    readonly type: string;
}
/**
 * Get complete history of an asset
 * @param direction The sort direction
 */
export function getAssetHistory(id: string, page: number = 1, per_page: number = 15, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AssetResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/history/${id}`, null, { 'page': page, 'per_page': per_page, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Get revision of an asset on a specific day
 */
export function getAssetByDate(id: string, date: string): Promise<AssetResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/history/${id}/date/${date}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get specific revision of an asset
 */
export function getAssetRevision(id: string, revision: number): Promise<AssetResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/history/${id}/rev/${revision}`, null, {}, {
        'Accept': ['application/json']
    });
}
