import __request__ from 'routing/request';
interface DataAdapterDto {
    readonly custom_error_ttl: number;
    readonly name: string;
    readonly description: string;
    readonly _scope: string;
    readonly id: string;
    readonly title: string;
    readonly content_pack: string;
    readonly config: LookupDataAdapterConfiguration;
    readonly custom_error_ttl_unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
    readonly custom_error_ttl_enabled: boolean;
}
interface LookupDataAdapterConfiguration {
    readonly type: string;
}
type DataAdapterDtoArray = DataAdapterDto[];
interface CSVFileDataAdapterOverrides {
    readonly overrides: {
        readonly [_key: string]: string;
    };
}
/**
 * List all Illuminate CSV File Data Adapters that can be overridden
 */
export function listDataAdapters(): Promise<DataAdapterDtoArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/overrides', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Provide override values for a specific Data Adapter
 */
export function setOverridesForDataAdapter(dataAdapterId: string, overrides: CSVFileDataAdapterOverrides): Promise<void> {
    return __request__('POST', `/plugins/org.graylog.plugins.illuminate/overrides/${dataAdapterId}`, overrides, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get override values for a specific Data Adapter
 */
export function getOverridesForAdapter(dataAdapterId: string): Promise<CSVFileDataAdapterOverrides> {
    return __request__('GET', `/plugins/org.graylog.plugins.illuminate/overrides/${dataAdapterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
