import React, { useCallback, useState } from 'react';

import { Button } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import type { DataWarehouse } from 'data-warehouse/Types';
import { MoreActions } from 'components/common/EntityDataTable';
import RetrievalArchiveButton from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveButton';
import RetrievalArchiveModal from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveModal';
import DeleteArchiveModal from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveModal';
import DeleteArchiveButton from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveButton';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';
import PreviewWarehouseLogsButton from 'data-warehouse/components/PreviewWarehouseLogsButton';
import { Spinner } from 'components/common';

const EntityActions = ({ archiveStreamId }: { archiveStreamId: string }) => {
  const { data: { valid: isValidLicense }, isInitialLoading: isLoadingLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleRetrievalModal = () => setShowRetrievalModal((cur) => !cur);
  const toggleDeleteModal = () => setShowDeleteModal((cur) => !cur);

  if (isLoadingLicense) {
    return <Spinner />;
  }

  return (
    <>
      {isValidLicense && (
        <>
          <PreviewWarehouseLogsButton streamId={archiveStreamId} />
          <RetrievalArchiveButton onClick={toggleRetrievalModal} buttonType="button" />
        </>
      )}
      <LinkContainer to={`${Routes.stream_view(archiveStreamId)}?segment=destinations`}>
        <Button bsStyle="info" bsSize="xsmall">Data Routing</Button>
      </LinkContainer>
      {isValidLicense && (
        <MoreActions>
          <DeleteArchiveButton onClick={toggleDeleteModal} />
        </MoreActions>
      )}

      {showRetrievalModal && (
        <RetrievalArchiveModal archiveStreamId={archiveStreamId}
                               onToggleModal={toggleRetrievalModal} />
      )}
      {showDeleteModal && (
        <DeleteArchiveModal archiveStreamId={archiveStreamId}
                            onToggleModal={toggleDeleteModal} />
      )}
    </>
  );
};

const useTableElements = () => {
  const entityActions = useCallback((dataWarehouse: DataWarehouse) => (
    <EntityActions archiveStreamId={dataWarehouse.stream_id} />
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
