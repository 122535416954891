import __request__ from 'routing/request';
interface anyMap {
    readonly [_key: string]: unknown;
}
/**
 * Delete existing theme overrides
 */
export function deleteThemeOverrides(): Promise<number> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.customization/theme', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get existing theme overrides
 */
export function getThemeOverrides(): Promise<anyMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.customization/theme', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update existing theme overrides
 */
export function updateThemeOverrides(overrides?: anyMap): Promise<anyMap> {
    return __request__('POST', '/plugins/org.graylog.plugins.customization/theme', overrides, {}, {
        'Accept': ['application/json']
    });
}
