import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface TrafficThresholdResponse {
    readonly state: TrafficThresholdState;
}
interface LicenseMetricsResponse {
    readonly last_7_days: number;
    readonly violations_last_30_days: number;
    readonly percent_remaining: number;
    readonly total_provisioned: number;
    readonly last_30_days: number;
    readonly last_365_days: number;
    readonly percent_usage_30_days: number;
    readonly current_day: number;
    readonly estimated_depletion_date: string;
    readonly estimated_days_to_depletion: number;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface License {
    readonly not_before_date: string;
    readonly expired: boolean;
    readonly daily_license: boolean;
    readonly issue_date: string;
    readonly contract_id: string;
    readonly subject: string;
    readonly draw_down_license: boolean;
    readonly id: string;
    readonly expiration_date: string;
    readonly version: number;
    readonly issuer: string;
}
interface PageListResponse_LicenseListEntity {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: LicenseListEntity[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface TrafficLimitViolationSettings {
    readonly email_recipients: string[];
}
interface integerMap {
    readonly [_key: string]: number;
}
interface LicenseToken {
    readonly not_before_date: string;
    readonly audience: Licensee;
    readonly issue_date: string;
    readonly subject: string;
    readonly id: string;
    readonly expiration_date: string;
    readonly issuer: string;
}
interface LicenseDetails {
    readonly expiration_warning_range: string;
    readonly traffic_provisioned: number;
    readonly allowed_remote_check_failures: number;
    readonly traffic_limit: number;
    readonly contract_id: string;
    readonly require_remote_check: boolean;
    readonly allowed_traffic_violations: number;
    readonly version: number;
    readonly traffic_check_range: string;
    readonly trial: boolean;
    readonly number_of_nodes: number;
}
type stringArray = string[];
interface TrafficLimitViolationsResponse {
    readonly state: TrafficLimitViolationState;
}
interface Licensee {
    readonly name: string;
    readonly company: string;
    readonly email: string;
}
interface TrafficLimitViolationState {
    readonly email_sent: boolean;
    readonly acknowledged: boolean;
    readonly most_recent_violation: string;
}
interface LicenseViolations {
    readonly violated: boolean;
    readonly expired: boolean;
    readonly traffic_exceeded: boolean;
    readonly remote_checks_failed: boolean;
    readonly nodes_exceeded: boolean;
    readonly cluster_not_covered: boolean;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface LicenseListEntity {
    readonly end_date: string;
    readonly license_type: string;
    readonly licensed_to: string;
    readonly license_id: string;
    readonly contact: string;
    readonly violations: LicenseViolations;
    readonly details: LicenseDetails;
    readonly id: string;
    readonly status: 'ACTIVE' | 'QUEUED' | 'EXPIRED' | 'INACTIVE' | 'UNDEFINED';
    readonly products: string;
    readonly start_date: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface LicenseStatusMap {
    readonly [_key: string]: LicenseStatus;
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface VerificationResult {
    readonly error_message: string;
    readonly license: LicenseToken;
}
interface LicenseStatus {
    readonly violated: boolean;
    readonly traffic_limit_violations: string[];
    readonly audience: Licensee;
    readonly expired_since: string;
    readonly traffic_exceeded: boolean;
    readonly active: boolean;
    readonly nodes_exceeded: boolean;
    readonly trial: boolean;
    readonly valid: boolean;
    readonly license: License;
    readonly expired: boolean;
    readonly expiration_upcoming: boolean;
    readonly draw_down_license: boolean;
    readonly remote_checks_failed: boolean;
    readonly expires_in: string;
    readonly cluster_not_covered: boolean;
}
type LicenseArray = License[];
interface LicenseStatusResponse {
    readonly status: LicenseStatus[];
}
interface TrafficThresholdState {
    readonly email_sent: boolean;
    readonly exceeded: boolean;
    readonly expired: boolean;
    readonly acknowledged: boolean;
    readonly most_recent_threshold: number;
}
/**
 * Show all installed licenses
 */
export function showLicenses(): Promise<LicenseArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Install new license
 */
export function uploadLicense(license: string): Promise<LicenseToken> {
    return __request__('POST', '/plugins/org.graylog.plugins.license/licenses', license, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete license and/or contract with given ID
 */
export function deleteLicense(license_id?: string, contract_id?: string): Promise<void> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.license/licenses', null, { 'license_id': license_id, 'contract_id': contract_id }, {
        'Accept': ['application/json']
    });
}
/**
 * Get the stats for consumed and remaining provisioned traffic
 */
export function getMetrics(): Promise<LicenseMetricsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/metrics', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show status for all installed licenses
 * @param only_legacy Only return legacy licenses
 */
export function showStatus(only_legacy: boolean = false): Promise<LicenseStatusResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/status', null, { 'only_legacy': only_legacy }, {
        'Accept': ['application/json']
    });
}
/**
 * Show status for currently active license
 */
export function showStatusActive(): Promise<LicenseListEntity> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/status/active', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get license status for given subject
 */
export function statusForSubject(subject?: string, bypass_cache: boolean = false): Promise<LicenseStatusMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/status/for-subject', null, { 'subject': subject, 'bypass_cache': bypass_cache }, {
        'Accept': ['application/json']
    });
}
/**
 * Show status for all installed licenses with pagination support
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function showStatusPaginated(sort: 'expiration_date' = 'expiration_date', page: number = 1, per_page: number = 50, query?: string, filters?: stringArray, order: 'asc' | ' desc' = 'asc'): Promise<PageListResponse_LicenseListEntity> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/status/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get info about traffic limit violations
 */
export function getTrafficLimitViolations(): Promise<TrafficLimitViolationsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Acknowledge traffic violations.
 */
export function acknowledgeTrafficLimitViolations(): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/acknowledgement', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get settings for traffic limit violations
 */
export function getTrafficLimitViolationSettings(): Promise<TrafficLimitViolationSettings> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/settings', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update settings for traffic limit violations
 */
export function putTrafficLimitViolationSettings(settings: TrafficLimitViolationSettings): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.license/licenses/traffic-limit-violations/settings', settings, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the time series data for remaining provisioned traffic
 * @param days For how many days the traffic stats should be returned
 * @param includeToday Whether the traffic should include up to the current date/time (in UTC).
 */
export function getRemaining(days: number = 30, includeToday: boolean = true): Promise<integerMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/traffic-remaining', null, { 'days': days, 'includeToday': includeToday }, {
        'Accept': ['application/json']
    });
}
/**
 * Get info about traffic threshold warning.
 */
export function getTrafficThreshold(): Promise<TrafficThresholdResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/traffic-threshold', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Acknowledge traffic threshold warning.
 */
export function acknowledgeTrafficThreshold(): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.license/licenses/traffic-threshold/acknowledgement', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check for valid license for given subject
 */
export function validityForSubject(subject?: string, bypass_cache: boolean = false): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.license/licenses/validity/for-subject', null, { 'subject': subject, 'bypass_cache': bypass_cache }, {
        'Accept': ['application/json']
    });
}
/**
 * Verify a license without saving
 */
export function verifyLicense(license: string): Promise<VerificationResult> {
    return __request__('POST', '/plugins/org.graylog.plugins.license/licenses/verify', license, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show license with given ID
 */
export function showLicense(licenseId: string): Promise<License> {
    return __request__('GET', `/plugins/org.graylog.plugins.license/licenses/${licenseId}`, null, {}, {
        'Accept': ['application/json']
    });
}
